import { combineReducers } from "redux";
import { IAction } from "../../schema";
import {
  IAddWebhookConfig,
  IDeleteWebhookConfig,
  IGetWebhookConfig,
  IGetWebhookLog,
  IUpdateWebhookConfig,
  webhookConst,
} from "../actions/webhook";

const getWebhookConfigReducer = (
  state: IGetWebhookConfig = {},
  action: IAction<IGetWebhookConfig>
): IGetWebhookConfig => {
  const { type, payload } = action;

  if (type === webhookConst.GET_WEBHOOK_CONFIG) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success",
        };
      default:
        return state;
    }
  }

  return state;
};

const addWebhookConfigReducer = (
  state: IAddWebhookConfig = {},
  action: IAction<IAddWebhookConfig>
): IAddWebhookConfig => {
  const { type, payload } = action;

  if (type === webhookConst.ADD_WEBHOOK_CONFIG) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success",
        };
      default:
        return state;
    }
  }

  return state;
};

const updateWebhookConfigReducer = (
  state: IUpdateWebhookConfig = {},
  action: IAction<IUpdateWebhookConfig>
): IUpdateWebhookConfig => {
  const { type, payload } = action;

  if (type === webhookConst.UPDATE_WEBHOOK_CONFIG) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success",
        };
      default:
        return state;
    }
  }

  return state;
};

const deleteWebhookConfigReducer = (
  state: IDeleteWebhookConfig = {},
  action: IAction<IDeleteWebhookConfig>
): IDeleteWebhookConfig => {
  const { type, payload } = action;

  if (type === webhookConst.DELETE_WEBHOOK_CONFIG) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success",
        };
      default:
        return state;
    }
  }

  return state;
};

const getWebhookLogsReducer = (
  state: IGetWebhookLog = {},
  action: IAction<IGetWebhookLog>
): IGetWebhookLog => {
  const { type, payload } = action;

  if (type === webhookConst.GET_WEBHOOK_LOGS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          ...payload,
          state: "success",
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootWebhookReducer = combineReducers({
  WebhookConfig: getWebhookConfigReducer,
  AddWebhookConfig: addWebhookConfigReducer,
  UpdateWebhookConfig: updateWebhookConfigReducer,
  DeleteWebhookConfig: deleteWebhookConfigReducer,
  WebhookLogs: getWebhookLogsReducer,
});

export interface IRWebhook {
  WebhookConfig: IGetWebhookConfig;
  AddWebhookConfig: IAddWebhookConfig;
  UpdateWebhookConfig: IUpdateWebhookConfig;
  DeleteWebhookConfig: IDeleteWebhookConfig;
  WebhookLogs: IGetWebhookLog;
}
