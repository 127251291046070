import { createAction } from ".";
import { IAPIError } from "../../schema";

export const accountConst = {
  GET_ACCOUNT_LIMITS: "GET_ACCOUNT_LIMITS",
  GET_ACCOUNT_STATUS: "GET_ACCOUNT_STATUS",
};

export interface IAccountLimits {
  limit_per_day?: number;
  limit_per_month?: number;
  is_pro_subscription_active?: boolean;
  is_project_management_enabled?: boolean;
  sender_domain_limit?: number;
}

export interface IGetAccountLimits extends IAccountLimits, IAPIError {}
export interface IGetAccountLimitsArgs {
  token: string;
}
export const getAccountLimitsAction = createAction<
  IGetAccountLimits,
  IGetAccountLimitsArgs
>(accountConst.GET_ACCOUNT_LIMITS);

// GET Account Status
export interface IGetAccountStatus extends IAPIError {
  is_existing_account?: boolean;
}
export interface IGetAccountStatusArgs {
  token: string;
}
export const getAccountStatusAction = createAction<
  IGetAccountStatus,
  IGetAccountStatusArgs
>(accountConst.GET_ACCOUNT_STATUS);
