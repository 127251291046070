import { combineReducers } from "redux";
import { IDefault, rootDefaultReducer } from "./default";
import { IUser, rootUserReducer } from "./user";
import { IRInsights, rootInsightsReducer } from "./insights";
import { IRSettings, rootSettingsReducer } from "./settings";
import { IRLogs, rootLogsReducer } from "./logs";
import { IRAccessKeys, rootAccessKeysReducer } from "./access_keys";
import { IRReferral, rootReferralReducer } from "./referral";
import { IRSubscription, rootSubscriptionReducer } from "./subscription";
import { IRSuppression, rootSuppressionReducer } from "./suppression";
import { IRWebhook, rootWebhookReducer } from "./webhook";
import { IRProject, rootProjectReducer } from "./projects";
import { IRAccount, rootAccountReducer } from "./account";

// Combine all reducers
const rootReducer = combineReducers({
  default: rootDefaultReducer,
  user: rootUserReducer,
  insights: rootInsightsReducer,
  settings: rootSettingsReducer,
  logs: rootLogsReducer,
  accessKeys: rootAccessKeysReducer,
  referral: rootReferralReducer,
  subscription: rootSubscriptionReducer,
  suppression: rootSuppressionReducer,
  webhook: rootWebhookReducer,
  Project: rootProjectReducer,
  account: rootAccountReducer,
});

// Combine all schemas
export interface RootSchema {
  default: IDefault;
  user: IUser;
  insights: IRInsights;
  settings: IRSettings;
  logs: IRLogs;
  accessKeys: IRAccessKeys;
  referral: IRReferral;
  subscription: IRSubscription;
  suppression: IRSuppression;
  webhook: IRWebhook;
  Project: IRProject;
  account: IRAccount;
}

export default rootReducer;
