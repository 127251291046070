import { createAction, ProjectArgs } from ".";
import { IAPIError } from "../../schema";

export const webhookConst = {
  GET_WEBHOOK_CONFIG: "GET_WEBHOOK_CONFIG",
  ADD_WEBHOOK_CONFIG: "ADD_WEBHOOK_CONFIG",
  UPDATE_WEBHOOK_CONFIG: "UPDATE_WEBHOOK_CONFIG",
  DELETE_WEBHOOK_CONFIG: "DELETE_WEBHOOK_CONFIG",
  GET_WEBHOOK_LOGS: "GET_WEBHOOK_LOGS",
};

export interface IWebhookConfig {
  data?: Array<IWebhookConfigData>;
}

export interface IWebhookConfigData {
  id: string;
  authorization: string;
  url: string;
  is_active: boolean;
  headers: Record<string, string>;
  events: Array<IWebhookConfigDataEvents>;
}

export interface IWebhookConfigDataEvents {
  type: string;
  sub_events: Array<string>;
}

export interface IGetWebhookConfig extends IWebhookConfig, IAPIError {}
export interface IGetWebhookConfigArgs extends ProjectArgs {
  token: string;
}

export const getWebhookConfigAction = createAction<
  IGetWebhookConfig,
  IGetWebhookConfigArgs
>(webhookConst.GET_WEBHOOK_CONFIG);

// Add config
export interface IAddWebhookConfig extends IAPIError {
  is_posted?: boolean;
}

export interface IAddWebhookConfigArgs extends ProjectArgs {
  token: string;
  authorization: string;
  post_url: string;
  is_active: boolean;
  headers: Record<string, string>;
  events: Array<IWebhookConfigDataEvents>;
  auth_username?: string;
  auth_password?: string;
  auth_bearer_token?: string;
}

export const addWebhookConfigAction = createAction<
  IAddWebhookConfig,
  IAddWebhookConfigArgs
>(webhookConst.ADD_WEBHOOK_CONFIG);

// update config
export interface IUpdateWebhookConfig extends IAPIError {
  is_posted?: boolean;
}

export interface IUpdateWebhookConfigArgs extends ProjectArgs {
  token: string;
  authorization: string;
  post_url: string;
  is_active: boolean;
  headers: Record<string, string>;
  events: Array<IWebhookConfigDataEvents>;
  auth_username?: string;
  auth_password?: string;
  auth_bearer_token?: string;
  id: string;
}

export const updateWebhookConfigAction = createAction<
  IUpdateWebhookConfig,
  IUpdateWebhookConfigArgs
>(webhookConst.UPDATE_WEBHOOK_CONFIG);

// remove config
export interface IDeleteWebhookConfig extends IAPIError {
  deleted?: boolean;
}

export interface IDeleteWebhookConfigArgs extends ProjectArgs {
  token: string;
  id: string;
}

export const deleteWebhookConfigAction = createAction<
  IDeleteWebhookConfig,
  IDeleteWebhookConfigArgs
>(webhookConst.DELETE_WEBHOOK_CONFIG);

// get webhook logs
export interface IGetWebhookLog extends IAPIError {
  data?: IWebhookLogData[];
  count?: number;
}

export interface IWebhookLogData {
  id?: string;
  message_id?: string;
  created_at?: Date;
  is_success?: boolean;
  url?: string;
  event_type?: string;
}

export interface IGetWebhookLogArgs extends ProjectArgs {
  token: string;
  id?: string;
}

export const getWebhookLogsAction = createAction<
  IGetWebhookLog,
  IGetWebhookLogArgs
>(webhookConst.GET_WEBHOOK_LOGS);
