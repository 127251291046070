import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { ProjectInsightsHttp } from "../../services/http";
import {
  getUsageInsightsAction,
  IGetUsageInsights,
  IGetInsightsArgs,
  getFailedTransactionInsightsAction,
  IGetFailedTransactionInsights,
  getGraphInsightsAction,
  IGetGraphInsights,
  insightsConst,
} from "../actions/insights";

function* getUsageInsightsHandler(args: IGetInsightsArgs) {
  try {
    yield put(getUsageInsightsAction.loading());

    const resp: IGetUsageInsights = yield ProjectInsightsHttp({
      token: args.token,
      projectId: args.projectId,
    }).get("/usage");

    if (resp.type) {
      throw new Error(resp.error);
    }

    yield put(getUsageInsightsAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getUsageInsightsAction.error(e.message));
  }
}

export function* watchGetUsageInsights() {
  while (true) {
    const { payload }: IAction<IGetInsightsArgs> = yield take(
      insightsConst.GET_USAGE_INSIGHTS
    );
    yield call(getUsageInsightsHandler, payload);
  }
}

function* getFailedTransactionInsightsHandler(args: IGetInsightsArgs) {
  try {
    yield put(getFailedTransactionInsightsAction.loading());

    const resp: IGetFailedTransactionInsights = yield ProjectInsightsHttp({
      token: args.token,
      projectId: args.projectId,
    }).get("/transaction");

    if (resp.type) {
      throw new Error(resp.error);
    }

    yield put(getFailedTransactionInsightsAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getFailedTransactionInsightsAction.error(e.message));
  }
}

export function* watchGetFailedTransactionInsights() {
  while (true) {
    const { payload }: IAction<IGetInsightsArgs> = yield take(
      insightsConst.GET_FAILED_TRANSACTION_INSIGHTS
    );
    yield call(getFailedTransactionInsightsHandler, payload);
  }
}

function* getGraphInsightsHandler(args: IGetInsightsArgs) {
  try {
    yield put(getGraphInsightsAction.loading());

    const resp: IGetGraphInsights = yield ProjectInsightsHttp({
      token: args.token,
      projectId: args.projectId,
    }).get("/historical_usage");

    if (resp.type) {
      throw new Error(resp.error);
    }

    yield put(getGraphInsightsAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getGraphInsightsAction.error(e.message));
  }
}

export function* watchGetGraphInsights() {
  while (true) {
    const { payload }: IAction<IGetInsightsArgs> = yield take(
      insightsConst.GET_GRAPH_INSIGHTS
    );
    yield call(getGraphInsightsHandler, payload);
  }
}
