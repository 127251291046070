import React from "react";
import { RouteComponentProps, RouteProps, useLocation } from "react-router";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { getCookie, removeCookie } from "../utils/urls";
import {
  IInvalidateTokenArgs,
  invalidateTokenAction,
} from "src/redux/actions/user";
import { Dispatch } from "redux";
import { RootSchema } from "../redux/reducers";
import { connect } from "react-redux";
import { appStateAction, IAppState } from "src/redux/actions/default";

// Navigations
const Auth = React.lazy(() => import("../containers/Auth"));
const UserProfile = React.lazy(() => import("../containers/UserProfile"));
const IntegrationGuide = React.lazy(() =>
  import("../containers/Guide/integration")
);

const Logs = React.lazy(() => import("../containers/Logs/index"));
const SenderAuth = React.lazy(() => import("../containers/SenderAuth"));
const Dashboard = React.lazy(() => import("../containers/Dashboard"));
const OnboardingSteps = React.lazy(() => import("../containers/Onboarding"));
const TestEmail = React.lazy(() => import("../containers/TestEmail"));
const SetupWizard = React.lazy(() => import("../containers/SetupWizard"));
const Insights = React.lazy(() => import("../containers/Insights"));
const AccessKeys = React.lazy(() => import("../containers/AccessKeys"));
const ReferPromo = React.lazy(() => import("../containers/ReferPromo"));
const AccountSubscription = React.lazy(() =>
  import("../containers/AccountSubscription")
);
const SuppressionBounces = React.lazy(() =>
  import("../containers/Suppression")
);
const SuppressionSpams = React.lazy(() =>
  import("../containers/Suppression/spam_reports")
);
const SuppressionUnsubscribes = React.lazy(() =>
  import("../containers/Suppression/unsubscribe_reports")
);
const RedeemPromo = React.lazy(() => import("../containers/RedeemPromo"));
const Webhook = React.lazy(() => import("../containers/Webhook"));
const Projects = React.lazy(() => import("../containers/Projects"));
const DedicatedIP = React.lazy(() => import("../containers/DedicatedIPs"));

export const FALLBACK = (
  <div id="preloader">
    <div id="status">
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    </div>
  </div>
);

export interface RoutesProps extends RouteComponentProps {
  invalidateTokenAction: (args: IInvalidateTokenArgs) => void;
  updateApp: (args: IAppState) => void;
}

class Routes extends React.Component<RoutesProps> {
  render() {
    return (
      <Switch>
        {/* Private Routes */}
        <ProtectedRoute
          exact
          path="/dashboard"
          render={() => (
            <React.Suspense fallback={FALLBACK}>
              <Dashboard />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/account-settings"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <UserProfile />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/guide/integrate"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <IntegrationGuide />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/logs"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <Logs />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/sender-auth"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <SenderAuth />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/onboarding/steps"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <OnboardingSteps />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/test"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <TestEmail />
            </React.Suspense>
          )}
        />
        <ProtectedRoute
          exact
          path="/setup-wizard"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <SetupWizard />
            </React.Suspense>
          )}
        />
        <ProtectedRoute
          exact
          path="/analytics"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <Insights />
            </React.Suspense>
          )}
        />
        <ProtectedRoute
          exact
          path="/access-keys"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <AccessKeys />
            </React.Suspense>
          )}
        />
        <ProtectedRoute
          exact
          path="/refer-promo"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <ReferPromo />
            </React.Suspense>
          )}
        />
        <ProtectedRoute
          exact
          path="/account-subscription"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <AccountSubscription />
            </React.Suspense>
          )}
        />
        <ProtectedRoute
          exact
          path="/suppressions/bounces"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <SuppressionBounces />
            </React.Suspense>
          )}
        />
        <ProtectedRoute
          exact
          path="/suppressions/spam-reports"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <SuppressionSpams />
            </React.Suspense>
          )}
        />
        <ProtectedRoute
          exact
          path="/suppressions/unsubscribes"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <SuppressionUnsubscribes />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/redeem-promo"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <RedeemPromo />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/webhook"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <Webhook />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/projects"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <Projects />
            </React.Suspense>
          )}
        />

        <ProtectedRoute
          exact
          path="/dedicated-ip"
          component={() => (
            <React.Suspense fallback={FALLBACK}>
              <DedicatedIP />
            </React.Suspense>
          )}
        />

        {/* Public Routes */}
        <Route
          exact
          path="/login"
          render={() => (
            <React.Suspense fallback={FALLBACK}>
              <Auth type="login" />
            </React.Suspense>
          )}
        />
        <Route
          exact
          path="/signup"
          render={() => (
            <React.Suspense fallback={FALLBACK}>
              <Auth type="registration" />
            </React.Suspense>
          )}
        />
        <Route
          exact
          path="/logout"
          render={({ location }) => {
            const token = removeCookie("accessToken") || "";
            localStorage.removeItem("fl_ov");
            this.props.invalidateTokenAction({ token: token });
            this.props.updateApp({ projectId: "" });
            const redirect =
              new URLSearchParams(location.search).get("redirect") ||
              "/dashboard";
            return <Redirect to={`/login?redirect=${redirect}`} />;
          }}
        />

        {/* Default route */}
        <Redirect to={"/login"} />
      </Switch>
    );
  }
}

const mapState = (state: RootSchema) => {
  return {};
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    invalidateTokenAction: (args: IInvalidateTokenArgs) =>
      dispatch(invalidateTokenAction.dispatch(args)),
    updateApp: (args: IAppState) => dispatch(appStateAction.success(args)),
  };
};

export default connect(mapState, mapDispatch)(withRouter(Routes));

// Functional Component
const ProtectedRoute: React.FC<RouteProps> = props => {
  const isLoggedIn = !!getCookie("accessToken");
  const { pathname, search } = useLocation();

  if (isLoggedIn) {
    return <Route {...props} />;
  } else return <Redirect to={`/login?redirect=${pathname + search}`} />;
};
