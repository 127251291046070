import { createAction, ProjectArgs } from ".";
import { IAPIError } from "../../schema";

export const projectConst = {
  GET_PROJECT_LIST: "GET_PROJECT_LIST",
  ADD_PROJECT: "ADD_PROJECT",
  SET_LIMIT: "SET_LIMIT",
  DELETE_PROJECT: "DELETE_PROJECT",
};

export interface IProject {
  id?: string;
  name?: string;
  is_deletable?: boolean;
  is_default?: boolean;
  created_at?: Date;
  limits?: IProjectLimits;
}

export interface IProjectLimits {
  per_month?: number;
  per_day?: number;
}

export interface IGetProjectList extends IAPIError {
  data?: IProject[];
}
export interface IGetProjectListArgs {
  token: string;
}
export const getProjectListAction = createAction<
  IGetProjectList,
  IGetProjectListArgs
>(projectConst.GET_PROJECT_LIST);

// Add Project
export interface IAddProject extends IAPIError {
  added?: boolean;
}
export interface IAddProjectArgs {
  token: string;
  name: string;
}
export const addProjectAction = createAction<IAddProject, IAddProjectArgs>(
  projectConst.ADD_PROJECT
);

// Set Project Limits
export interface ISetProjectLimits extends IAPIError {
  added?: boolean;
}
export interface ISetProjectLimitsArgs extends ProjectArgs {
  token: string;
  per_month?: number;
}
export const setProjectLimitsAction = createAction<
  ISetProjectLimits,
  ISetProjectLimitsArgs
>(projectConst.SET_LIMIT);

// Delete Project
export interface IDeleteProject extends IAPIError {
  added?: boolean;
}
export interface IDeleteProjectArgs extends ProjectArgs {
  token: string;
}
export const deleteProjectAction = createAction<
  IDeleteProject,
  IDeleteProjectArgs
>(projectConst.DELETE_PROJECT);
