import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { ConfigHttp } from "../../services/http";
import {
  accountConst,
  getAccountLimitsAction,
  getAccountStatusAction,
  IGetAccountLimits,
  IGetAccountLimitsArgs,
  IGetAccountStatus,
  IGetAccountStatusArgs,
} from "../actions/account";

function* getAccountLimitsHandler(args: IGetAccountLimitsArgs) {
  try {
    yield put(getAccountLimitsAction.loading());

    const resp: IGetAccountLimits = yield ConfigHttp({ token: args.token }).get(
      "/limits"
    );

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getAccountLimitsAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getAccountLimitsAction.error(e.message));
  }
}

export function* watchGetAccountLimits() {
  while (true) {
    const { payload }: IAction<IGetAccountLimitsArgs> = yield take(
      accountConst.GET_ACCOUNT_LIMITS
    );
    yield call(getAccountLimitsHandler, payload);
  }
}

function* getAccountStatusHandler(args: IGetAccountStatusArgs) {
  try {
    yield put(getAccountStatusAction.loading());

    const resp: IGetAccountStatus = yield ConfigHttp({ token: args.token }).get(
      "/status"
    );

    if (resp.error) {
      yield put(getAccountStatusAction.error(resp.error));
    } else {
      yield put(getAccountStatusAction.success(resp));
    }
  } catch (e) {
    // @ts-ignore
    yield put(getAccountStatusAction.error(e.message));
  }
}

export function* watchGetAccountStatus() {
  while (true) {
    const { payload }: IAction<IGetAccountStatusArgs> = yield take(
      accountConst.GET_ACCOUNT_STATUS
    );
    yield call(getAccountStatusHandler, payload);
  }
}
