import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { ProjectConfigHttp } from "../../services/http";
import {
  IGetAccessKeysArgs,
  IGetAccessKeys,
  getAccessKeysAction,
  generateAccessKeyAction,
  IGenerateAccessKey,
  IGenerateAccessKeyArgs,
  IRegenerateAccessSecret,
  IRegenerateAccessSecretArgs,
  regenerateAccessSecretAction,
  accessKeysConst,
  IRemoveAccessKeyArgs,
  removeAccessKeyAction,
} from "../actions/access_keys";

function* getAccessKeysHandler(args: IGetAccessKeysArgs) {
  try {
    yield put(getAccessKeysAction.loading());

    const resp: IGetAccessKeys = yield ProjectConfigHttp({
      token: args.token,
      projectId: args.projectId,
    }).get("/access_keys");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getAccessKeysAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getAccessKeysAction.error(e.message));
  }
}

export function* watchGetAccessKeys() {
  while (true) {
    const { payload }: IAction<IGetAccessKeysArgs> = yield take(
      accessKeysConst.GET_ACCESS_KEYS
    );
    yield call(getAccessKeysHandler, payload);
  }
}

function* generateAccessKeyHandler(args: IGenerateAccessKeyArgs) {
  try {
    yield put(generateAccessKeyAction.loading());

    const resp: IGenerateAccessKey = yield ProjectConfigHttp({
      token: args.token,
      projectId: args.projectId,
    }).post("/access_keys", {}, JSON.stringify({ name: args.name }));

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(generateAccessKeyAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(generateAccessKeyAction.error(e.message));
  }
}

export function* watchGenerateAccessKey() {
  while (true) {
    const { payload }: IAction<IGenerateAccessKeyArgs> = yield take(
      accessKeysConst.GENERATE_ACCESS_KEY
    );
    yield call(generateAccessKeyHandler, payload);
  }
}

function* regenerateAccessSecretHandler(args: IRegenerateAccessSecretArgs) {
  try {
    yield put(regenerateAccessSecretAction.loading());

    const resp: IRegenerateAccessSecret = yield ProjectConfigHttp({
      token: args.token,
      projectId: args.projectId,
    }).put("/access_keys", {}, JSON.stringify({ api_key: args.api_key }));

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(regenerateAccessSecretAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(regenerateAccessSecretAction.error(e.message));
  }
}

export function* watchRegenerateAccessSecret() {
  while (true) {
    const { payload }: IAction<IRegenerateAccessSecretArgs> = yield take(
      accessKeysConst.REGENERATE_ACCESS_SECRET
    );
    yield call(regenerateAccessSecretHandler, payload);
  }
}

function* removeAccessKeyHandler(args: IRemoveAccessKeyArgs) {
  try {
    yield put(removeAccessKeyAction.loading());

    const resp: IRegenerateAccessSecret = yield ProjectConfigHttp({
      token: args.token,
      projectId: args.projectId,
    }).delete("/access_keys/" + args.api_key, {});

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(removeAccessKeyAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(removeAccessKeyAction.error(e.message));
  }
}

export function* watchRemoveAccessKey() {
  while (true) {
    const { payload }: IAction<IRemoveAccessKeyArgs> = yield take(
      accessKeysConst.REMOVE_ACCESS_KEY
    );
    yield call(removeAccessKeyHandler, payload);
  }
}
