import { combineReducers } from "redux";
import { IAction } from "../../schema";
import {
  accessKeysConst,
  IGetAccessKeys,
  IGenerateAccessKey,
  IRegenerateAccessSecret,
  IRemoveAccessKey,
} from "../actions/access_keys";

const getAccessKeysReducer = (
  state: IGetAccessKeys = {},
  action: IAction<IGetAccessKeys>
): IGetAccessKeys => {
  const { type, payload } = action;

  if (type === accessKeysConst.GET_ACCESS_KEYS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", data: payload.data };
      default:
        return state;
    }
  }

  return state;
};

const generateAccessKeyReducer = (
  state: IGenerateAccessKey = {},
  action: IAction<IGenerateAccessKey>
): IGenerateAccessKey => {
  const { type, payload } = action;

  if (type === accessKeysConst.GENERATE_ACCESS_KEY) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          api_key: payload.api_key,
          api_secret: payload.api_secret,
          name: payload.name,
        };
      default:
        return state;
    }
  }

  return state;
};

const regenerateAccessSecretReducer = (
  state: IRegenerateAccessSecret = {},
  action: IAction<IRegenerateAccessSecret>
): IRegenerateAccessSecret => {
  const { type, payload } = action;

  if (type === accessKeysConst.REGENERATE_ACCESS_SECRET) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          api_key: payload.api_key,
          api_secret: payload.api_secret,
          name: payload.name,
        };
      default:
        return state;
    }
  }

  return state;
};

const removeAccessKeyReducer = (
  state: IRemoveAccessKey = {},
  action: IAction<IRemoveAccessKey>
): IRemoveAccessKey => {
  const { type, payload } = action;

  if (type === accessKeysConst.REMOVE_ACCESS_KEY) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return {
          ...state,
          state: "success",
          deleted: payload.deleted,
        };
      default:
        return state;
    }
  }

  return state;
};

export const rootAccessKeysReducer = combineReducers({
  AccessKeys: getAccessKeysReducer,
  GenerateAccessKey: generateAccessKeyReducer,
  RegenerateAccessSecret: regenerateAccessSecretReducer,
  RemoveAccessKey: removeAccessKeyReducer,
});

export interface IRAccessKeys {
  AccessKeys: IGetAccessKeys;
  GenerateAccessKey: IGenerateAccessKey;
  RegenerateAccessSecret: IRegenerateAccessSecret;
  RemoveAccessKey: IRemoveAccessKey;
}
