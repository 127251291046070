import { createAction, ProjectArgs } from ".";
import { IAPIError } from "../../schema";

export const accessKeysConst = {
  GET_ACCESS_KEYS: "GET_ACCESS_KEYS",
  GENERATE_ACCESS_KEY: "GENERATE_ACCESS_KEY",
  REGENERATE_ACCESS_SECRET: "REGENERATE_ACCESS_SECRET",
  REMOVE_ACCESS_KEY: "REMOVE_ACCESS_KEY",
};

export interface IAccessKeys {
  name?: string;
  api_key?: string;
  api_secret?: string;
}

export interface IGetAccessKeys extends IAPIError {
  data?: IAccessKeys[];
}
export interface IGetAccessKeysArgs extends ProjectArgs {
  token: string;
}
export const getAccessKeysAction = createAction<
  IGetAccessKeys,
  IGetAccessKeysArgs
>(accessKeysConst.GET_ACCESS_KEYS);

export interface IGenerateAccessKey extends IAccessKeys, IAPIError {}
export interface IGenerateAccessKeyArgs extends ProjectArgs {
  token: string;
  name: string;
}
export const generateAccessKeyAction = createAction<
  IGenerateAccessKey,
  IGenerateAccessKeyArgs
>(accessKeysConst.GENERATE_ACCESS_KEY);

export interface IRegenerateAccessSecret extends IAccessKeys, IAPIError {}
export interface IRegenerateAccessSecretArgs extends ProjectArgs {
  token: string;
  api_key: string;
}
export const regenerateAccessSecretAction = createAction<
  IRegenerateAccessSecret,
  IRegenerateAccessSecretArgs
>(accessKeysConst.REGENERATE_ACCESS_SECRET);

export interface IRemoveAccessKey extends IAPIError {
  deleted?: boolean;
}
export interface IRemoveAccessKeyArgs extends ProjectArgs {
  token: string;
  api_key: string;
}
export const removeAccessKeyAction = createAction<
  IRemoveAccessKey,
  IRemoveAccessKeyArgs
>(accessKeysConst.REMOVE_ACCESS_KEY);
