import { createAction, ProjectArgs } from ".";
import { IAPIError } from "../../schema";

export const settingsConst = {
  GET_SENDER_VERIFICATION_SETTINGS: "GET_SENDER_VERIFICATION_SETTINGS",
  SET_SENDER_DOMAIN: "SET_SENDER_DOMAIN",
  VERIFY_SENDER_DOMAIN: "VERIFY_SENDER_DOMAIN",
  SEND_TEST_EMAIL: "SEND_TEST_EMAIL",
  RESET_SENDER_DOMAIN: "RESET_SENDER_DOMAIN",
  GET_SENDER_VERIFICATION_KEYS: "GET_SENDER_VERIFICATION_KEYS",
  SEND_SENDER_VERIFICATION_KEYS_EMAIL: "SEND_SENDER_VERIFICATION_KEYS_EMAIL",
  SET_SENDER_INTEGRATION_STATUS: "SET_SENDER_INTEGRATION_STATUS",
  GET_TRACK_OPTIONS_STATUS: "GET_TRACK_OPTIONS_STATUS",
  SET_TRACK_OPTIONS_STATUS: "SET_TRACK_OPTIONS_STATUS",
  GET_DEDICATED_IP: "GET_DEDICATED_IP",
  ALLOCATE_DEDICATED_IP: "ALLOCATE_DEDICATED_IP",
  VERIFY_DEDICATED_IP: "VERIFY_DEDICATED_IP",
};

export interface ISenderVerificationSettings {
  data?: Array<ISenderDomains>;
  integration_configured?: boolean;
  test_email_sent?: boolean;
  is_account_locked?: boolean;
  is_attachments_allowed?: boolean;
}

export interface ISenderDomains {
  domain?: string;
  spf_verified?: boolean;
  dkim_verified_s1?: boolean;
  dkim_verified_s2?: boolean;
  allow_send_email?: boolean;
}

export interface IGetSenderVerificationSettings
  extends ISenderVerificationSettings,
    IAPIError {}

export interface IGetSenderVerificationArgs extends ProjectArgs {
  token: string;
}

export const getVerificationSettingsAction = createAction<
  IGetSenderVerificationSettings,
  IGetSenderVerificationArgs
>(settingsConst.GET_SENDER_VERIFICATION_SETTINGS);

// Set Sender Domain Action
export interface ISenderDomain {
  accepted?: boolean;
}

export interface ISetSenderDomain extends ISenderDomain, IAPIError {}
export interface ISetSenderDomainArgs extends IGetSenderVerificationArgs {
  domain?: string;
}
export const setSenderDomainAction = createAction<
  ISetSenderDomain,
  ISetSenderDomainArgs
>(settingsConst.SET_SENDER_DOMAIN);

// Verify Sender Action
export interface ISenderDomainVerification {
  verified?: boolean;
}

export interface IVerifySenderDomain
  extends ISenderDomainVerification,
    IAPIError {}
export interface IVerifySenderDomainArgs extends IGetSenderVerificationArgs {
  domain?: string;
}
export const verifySenderDomainAction = createAction<
  IVerifySenderDomain,
  IVerifySenderDomainArgs
>(settingsConst.VERIFY_SENDER_DOMAIN);

export interface ISendTestEmail extends IAPIError {
  sent?: boolean;
}
export interface ISendTestEmailArgs extends IGetSenderVerificationArgs {
  from: string;
  rcpt_to: string;
}
export const sendTestEmailAction = createAction<
  ISendTestEmail,
  ISendTestEmailArgs
>(settingsConst.SEND_TEST_EMAIL);

// Reset Sender Domain
export interface IResetSenderDomain extends IAPIError {
  accepted?: boolean;
}
export interface IResetSenderDomainArgs extends IGetSenderVerificationArgs {
  domain?: string;
}
export const resetSenderDomainAction = createAction<
  IResetSenderDomain,
  IResetSenderDomainArgs
>(settingsConst.RESET_SENDER_DOMAIN);

// Get Sender Domain Verifiaction DNS Keys
export interface DNSRecords {
  type?: string;
  key?: string;
  value?: string;
}

export interface IGetSenderDomainVerificationKeys extends IAPIError {
  dns_records?: Array<DNSRecords>;
}
export interface IGetSenderDomainVerificationKeysArgs
  extends IGetSenderVerificationArgs {
  domain: string;
}

export const getSenderDomainVerificationKeys = createAction<
  IGetSenderDomainVerificationKeys,
  IGetSenderDomainVerificationKeysArgs
>(settingsConst.GET_SENDER_VERIFICATION_KEYS);

// Email Sender Domain Verifiaction DNS Keys
export interface ISendSenderDomainVerificationKeysEmail extends IAPIError {
  sent?: boolean;
}
export interface ISendSenderDomainVerificationKeysEmailArgs
  extends IGetSenderVerificationArgs {
  rcpt_to?: string;
  sender_name?: string;
  sender_email?: string;
  domain?: string;
}
export const sendSenderDomainVerificationKeysEmail = createAction<
  ISendSenderDomainVerificationKeysEmail,
  ISendSenderDomainVerificationKeysEmailArgs
>(settingsConst.SEND_SENDER_VERIFICATION_KEYS_EMAIL);

// Set Sender Integration Status
export interface ISetSenderIntegrationStatus extends IAPIError {
  posted?: boolean;
}
export interface ISetSenderIntegrationStatusArgs
  extends IGetSenderVerificationArgs {
  has_configured?: boolean;
}
export const setSenderIntegrationStatus = createAction<
  ISetSenderIntegrationStatus,
  ISetSenderIntegrationStatusArgs
>(settingsConst.SET_SENDER_INTEGRATION_STATUS);

// Get Tracking Options
export interface IGetTrackOptionsStatus extends IAPIError {
  open_tracking?: boolean;
  click_tracking?: boolean;
}
export interface IGetTrackOptionsStatusArgs
  extends IGetSenderVerificationArgs {}
export const getTrackOptionsStatus = createAction<
  IGetTrackOptionsStatus,
  IGetTrackOptionsStatusArgs
>(settingsConst.GET_TRACK_OPTIONS_STATUS);

// Set Tracking Options
export interface ISetTrackOptionsStatus extends IAPIError {
  open_tracking?: boolean;
  click_tracking?: boolean;
}
export interface ISetTrackOptionsStatusArgs extends IGetSenderVerificationArgs {
  open_tracking?: boolean;
  click_tracking?: boolean;
}
export const setTrackOptionsStatus = createAction<
  ISetTrackOptionsStatus,
  ISetTrackOptionsStatusArgs
>(settingsConst.SET_TRACK_OPTIONS_STATUS);

// Get Dedicated IP
export interface IGetDedicatedIP extends IAPIError {
  data?: Array<IDedicatedIPData>;
}
export interface IDedicatedIPData {
  allocation_id?: string;
  ip_address?: string;
  dns_records?: Array<IAllocationDedicatedIPDnsRecords>;
  reverse_dns?: string;
  is_reverse_dns_verified?: boolean;
  allocated?: boolean;
}
export interface IGetDedicatedIPArgs extends IGetSenderVerificationArgs {}
export const getDedicatedIP = createAction<
  ISetTrackOptionsStatus,
  ISetTrackOptionsStatusArgs
>(settingsConst.GET_DEDICATED_IP);

// Allocate Dedicated IP
export interface IAllocateDedicatedIP extends IAPIError {
  allocation_id?: string;
  ip_address?: string;
  dns_records?: Array<IAllocationDedicatedIPDnsRecords>;
}
export interface IAllocationDedicatedIPDnsRecords {
  type?: string;
  key?: string;
  value?: string;
}
export interface IAllocateDedicatedIPArgs extends IGetSenderVerificationArgs {
  domain: string;
}
export const allocateDedicatedIP = createAction<
  IAllocateDedicatedIP,
  IAllocateDedicatedIPArgs
>(settingsConst.ALLOCATE_DEDICATED_IP);

// Verify Dedicated IP
export interface IVerifyDedicatedIP extends IAPIError {
  is_posted?: boolean;
}
export interface IVerifyDedicatedIPArgs extends IGetSenderVerificationArgs {
  allocation_id: string;
}
export const verifyDedicatedIP = createAction<
  IVerifyDedicatedIP,
  IVerifyDedicatedIPArgs
>(settingsConst.VERIFY_DEDICATED_IP);
