import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { ProjectConfigHttp } from "../../services/http";
import {
  getSuppressionAction,
  IGetSuppressionBounces,
  IGetSuppressionBounceArgs,
  suppressionConst,
  IGetSuppressionSpamReportsArgs,
  getSuppressionSpamReportsAction,
  IGetSuppressionSpamReports,
  IGetSuppressionUnsubscribeReportsArgs,
  getSuppressionUnsubscribeReportsAction,
  IDeleteSuppressionBounceArgs,
  deleteSuppressionBounceAction,
  IDeleteSuppressionBounce,
} from "../actions/suppression";

function* getSuppressionBouncesHandler(args: IGetSuppressionBounceArgs) {
  try {
    yield put(getSuppressionAction.loading());

    const resp: IGetSuppressionBounces = yield ProjectConfigHttp({
      token: args.token,
      projectId: args.projectId,
    }).get(`/sender/suppression/bounce?page=${args.page}`);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getSuppressionAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getSuppressionAction.error(e.message));
  }
}

export function* watchGetSuppressionBounces() {
  while (true) {
    const { payload }: IAction<IGetSuppressionBounceArgs> = yield take(
      suppressionConst.GET_SUPPRESSION_BOUNCE_LIST
    );
    yield call(getSuppressionBouncesHandler, payload);
  }
}

function* getSuppressionSpamsHandler(args: IGetSuppressionSpamReportsArgs) {
  try {
    yield put(getSuppressionSpamReportsAction.loading());

    const resp: IGetSuppressionSpamReports = yield ProjectConfigHttp({
      token: args.token,
      projectId: args.projectId,
    }).get(`/sender/suppression/spam-reports?page=${args.page}`);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getSuppressionSpamReportsAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getSuppressionSpamReportsAction.error(e.message));
  }
}

export function* watchGetSuppressionSpams() {
  while (true) {
    const { payload }: IAction<IGetSuppressionSpamReportsArgs> = yield take(
      suppressionConst.GET_SUPPRESSION_SPAM_LIST
    );
    yield call(getSuppressionSpamsHandler, payload);
  }
}

function* getSuppressionUnsubscribesHandler(
  args: IGetSuppressionUnsubscribeReportsArgs
) {
  try {
    yield put(getSuppressionUnsubscribeReportsAction.loading());

    const resp: IGetSuppressionSpamReports = yield ProjectConfigHttp({
      token: args.token,
      projectId: args.projectId,
    }).get(`/sender/suppression/unsubscribes?page=${args.page}`);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getSuppressionUnsubscribeReportsAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getSuppressionUnsubscribeReportsAction.error(e.message));
  }
}

export function* watchGetSuppressionUnsubscribes() {
  while (true) {
    const {
      payload,
    }: IAction<IGetSuppressionUnsubscribeReportsArgs> = yield take(
      suppressionConst.GET_SUPPRESSION_UNSUBSCRIBE_LIST
    );
    yield call(getSuppressionUnsubscribesHandler, payload);
  }
}

function* removeSuppressionBounceHandler(args: IDeleteSuppressionBounceArgs) {
  try {
    yield put(deleteSuppressionBounceAction.loading());

    const resp: IDeleteSuppressionBounce = yield ProjectConfigHttp({
      token: args.token,
      projectId: args.projectId,
    }).delete(`/sender/suppression/bounce`, { id: args.id });

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(deleteSuppressionBounceAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(deleteSuppressionBounceAction.error(e.message));
  }
}

export function* watchRemoveSuppressionBounce() {
  while (true) {
    const { payload }: IAction<IDeleteSuppressionBounceArgs> = yield take(
      suppressionConst.DELETE_SUPPRESSION_BOUNCE
    );
    yield call(removeSuppressionBounceHandler, payload);
  }
}
