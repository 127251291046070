import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import Http from "../../services/http";
import {
  IGetSubscriptionURL,
  IGetSubscriptionURLArgs,
  getSubscriptionURLAction,
  subscriptionConst,
  IGetSubscriptionStatusArgs,
  getSubscriptionStatusAction,
  IGetSubscriptionStatus,
  IGetSubscriptionEditURLArgs,
  getSubscriptionEditURLAction,
  IGetPricingArgs,
  getPricingAction,
  IGetPricing,
  IRedeemPromoArgs,
  redeemPromoAction,
  IRedeemPromo,
  IGetRedeemedPromoArgs,
  getRedeemedPromoAction,
  IGetInvoicesArgs,
  getInvoicesAction,
  getSubscriptionMigrationStatusAction,
  IGetSubscriptionMigrationStatusArgs,
  IGetSubscriptionMigrateURLArgs,
  getSubscriptionMigrateURLAction,
  IGetDedicatedIPPricingArgs,
  getDedicatedIPPricing,
  IGetDedicatedIPPricing,
} from "../actions/subscription";
import config from "../../config";

function* getSubscriptionURLHandler(args: IGetSubscriptionURLArgs) {
  try {
    yield put(getSubscriptionURLAction.loading());

    if (args.origin) {
      args.origin = btoa(args.origin);
    }

    const resp: IGetSubscriptionURL = yield new Http({
      endpoint: config.api_endpoint,
      headers: { Authorization: "Bearer " + args.token },
    }).get(
      `/account/subscription/url?currency_id=${args.currency_id}&origin=${args.origin}`
    );

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getSubscriptionURLAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getSubscriptionURLAction.error(e.message));
  }
}

export function* watchGetSubscriptionURL() {
  while (true) {
    const { payload }: IAction<IGetSubscriptionURLArgs> = yield take(
      subscriptionConst.GET_SUBSCRIBE_URL
    );
    yield call(getSubscriptionURLHandler, payload);
  }
}

function* getSubscriptionStatusHandler(args: IGetSubscriptionStatusArgs) {
  try {
    yield put(getSubscriptionStatusAction.loading());

    const resp: IGetSubscriptionStatus = yield new Http({
      endpoint: config.api_endpoint,
      headers: { Authorization: "Bearer " + args.token },
    }).get("/account/subscription/status");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getSubscriptionStatusAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getSubscriptionStatusAction.error(e.message));
  }
}

export function* watchGetSubscriptionStatus() {
  while (true) {
    const { payload }: IAction<IGetSubscriptionStatusArgs> = yield take(
      subscriptionConst.GET_SUBSCRIPTION_STATUS
    );
    yield call(getSubscriptionStatusHandler, payload);
  }
}

function* getSubscriptionEditURLHandler(args: IGetSubscriptionEditURLArgs) {
  try {
    yield put(getSubscriptionEditURLAction.loading());

    const resp: IGetSubscriptionURL = yield new Http({
      endpoint: config.api_endpoint,
      headers: { Authorization: "Bearer " + args.token },
    }).get("/account/subscription/edit-url");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getSubscriptionEditURLAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getSubscriptionEditURLAction.error(e.message));
  }
}

export function* watchGetSubscriptionEditURL() {
  while (true) {
    const { payload }: IAction<IGetSubscriptionEditURLArgs> = yield take(
      subscriptionConst.GET_SUBSCRIBE_EDIT_URL
    );
    yield call(getSubscriptionEditURLHandler, payload);
  }
}

function* getPricingHandler(args: IGetPricingArgs) {
  try {
    yield put(getPricingAction.loading());

    const resp: IGetPricing = yield new Http({
      endpoint: config.api_endpoint,
    }).get("/account/subscription/pricing");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getPricingAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getPricingAction.error(e.message));
  }
}

export function* watchGetPricing() {
  while (true) {
    const { payload }: IAction<IGetPricingArgs> = yield take(
      subscriptionConst.GET_PRICING
    );
    yield call(getPricingHandler, payload);
  }
}

function* redeemPromoHandler(args: IRedeemPromoArgs) {
  try {
    yield put(redeemPromoAction.loading());

    const resp: IRedeemPromo = yield new Http({
      endpoint: config.api_endpoint,
      headers: { Authorization: "Bearer " + args.token },
    }).post("/account/subscription/redeem-code", {}, JSON.stringify(args));

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(redeemPromoAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(redeemPromoAction.error(e.message));
  }
}

export function* watchRedeemPromo() {
  while (true) {
    const { payload }: IAction<IRedeemPromoArgs> = yield take(
      subscriptionConst.REDEEM_PROMO
    );
    yield call(redeemPromoHandler, payload);
  }
}

function* getRedeemedPromoHandler(args: IGetRedeemedPromoArgs) {
  try {
    yield put(getRedeemedPromoAction.loading());

    const resp: IRedeemPromo = yield new Http({
      endpoint: config.api_endpoint,
      headers: { Authorization: "Bearer " + args.token },
    }).get("/account/subscription/redeem-code");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getRedeemedPromoAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getRedeemedPromoAction.error(e.message));
  }
}

export function* watchGetRedeemedPromo() {
  while (true) {
    const { payload }: IAction<IGetRedeemedPromoArgs> = yield take(
      subscriptionConst.GET_REDEEMED_PROMO
    );
    yield call(getRedeemedPromoHandler, payload);
  }
}

function* getInvoicesHandler(args: IGetInvoicesArgs) {
  try {
    yield put(getInvoicesAction.loading());

    const resp: IRedeemPromo = yield new Http({
      endpoint: config.api_endpoint,
      headers: { Authorization: "Bearer " + args.token },
    }).get("/account/subscription/invoices");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getInvoicesAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getInvoicesAction.error(e.message));
  }
}

export function* watchGetInvoices() {
  while (true) {
    const { payload }: IAction<IGetInvoicesArgs> = yield take(
      subscriptionConst.GET_INVOICES
    );
    yield call(getInvoicesHandler, payload);
  }
}

function* getSubscriptionMigrationStatusHandler(
  args: IGetSubscriptionMigrationStatusArgs
) {
  try {
    yield put(getSubscriptionMigrationStatusAction.loading());

    const resp: IRedeemPromo = yield new Http({
      endpoint: config.api_endpoint,
      headers: { Authorization: "Bearer " + args.token },
    }).get("/account/subscription/migrate-status");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getSubscriptionMigrationStatusAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getSubscriptionMigrationStatusAction.error(e.message));
  }
}

export function* watchGetSubscriptionMigrationStatus() {
  while (true) {
    const {
      payload,
    }: IAction<IGetSubscriptionMigrationStatusArgs> = yield take(
      subscriptionConst.GET_SUBSCRIPTION_MIGRATION_STATUS
    );
    yield call(getSubscriptionMigrationStatusHandler, payload);
  }
}

function* getSubscriptionMigrationUrlHandler(
  args: IGetSubscriptionMigrateURLArgs
) {
  try {
    yield put(getSubscriptionMigrateURLAction.loading());

    if (args.origin) {
      args.origin = btoa(args.origin);
    }

    const resp: IRedeemPromo = yield new Http({
      endpoint: config.api_endpoint,
      headers: { Authorization: "Bearer " + args.token },
    }).get("/account/subscription/migrate-url?origin=" + args.origin);

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getSubscriptionMigrateURLAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getSubscriptionMigrateURLAction.error(e.message));
  }
}

export function* watchGetSubscriptionMigrationUrl() {
  while (true) {
    const { payload }: IAction<IGetSubscriptionMigrateURLArgs> = yield take(
      subscriptionConst.GET_SUBSCRIBE_MIGRATION_URL
    );
    yield call(getSubscriptionMigrationUrlHandler, payload);
  }
}

function* getDedicatedIPPricingHandler(args: IGetDedicatedIPPricingArgs) {
  try {
    yield put(getDedicatedIPPricing.loading());

    const resp: IGetDedicatedIPPricing = yield new Http({
      endpoint: config.api_endpoint,
    }).get("/account/subscription/dedicated-ip/pricing");

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getDedicatedIPPricing.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getDedicatedIPPricing.error(e.message));
  }
}

export function* watchGetDedicatedIPPricing() {
  while (true) {
    const { payload }: IAction<IGetDedicatedIPPricingArgs> = yield take(
      subscriptionConst.GET_DEDICATED_IP_PRICING
    );
    yield call(getDedicatedIPPricingHandler, payload);
  }
}
