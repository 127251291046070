import { all } from "redux-saga/effects";
import {
  watchGetProfile,
  watchVerifyProfile,
  watchRegisterProfile,
  watchInvalidateToken,
  watchResendVerificationEmail,
  watchAddEmail,
  watchReauthAccount,
  watchUpdateProfile,
  watchTrackSignup,
  watchSignupRequestLead,
  watchLoginWithMagicLink,
  watchCheckMagicLinkAuthStatus,
  watchResendMagicLink,
} from "./user";
import {
  watchGetUsageInsights,
  watchGetFailedTransactionInsights,
  watchGetGraphInsights,
} from "./insights";
import {
  watchGetVerificationSettings,
  watchSetSenderDomain,
  watchVerifySenderDomain,
  watchSendTestEmailSPF,
  watchResetSenderDomain,
  watchGetSenderDomainVerificationKeys,
  watchSendSenderVerificationKeysEmail,
  watchSetSenderIntegrationStatus,
  watchGetTrackOptionsStatus,
  watchSetTrackOptionsStatus,
  watchDedicatedIP,
  watchAllocateDedicatedIP,
  watchVerifyDedicatedIP,
} from "./settings";
import { watchGetLogs, watchGetLogsByDate, watchGetLogsByEmail } from "./logs";
import {
  watchGenerateAccessKey,
  watchGetAccessKeys,
  watchRegenerateAccessSecret,
  watchRemoveAccessKey,
} from "./access_keys";
import {
  watchGetReferralStatsByEmail,
  watchCreateReferralInvite,
} from "./referral";
import {
  watchGetDedicatedIPPricing,
  watchGetInvoices,
  watchGetPricing,
  watchGetRedeemedPromo,
  watchGetSubscriptionEditURL,
  watchGetSubscriptionMigrationStatus,
  watchGetSubscriptionMigrationUrl,
  watchGetSubscriptionStatus,
  watchGetSubscriptionURL,
  watchRedeemPromo,
} from "./subscription";
import {
  watchGetSuppressionBounces,
  watchGetSuppressionSpams,
  watchGetSuppressionUnsubscribes,
  watchRemoveSuppressionBounce,
} from "./suppression";
import {
  watchAddWebhookConfig,
  watchDeleteWebhookConfig,
  watchGetWebhookConfig,
  watchGetWebhookLogs,
  watchUpdateWebhookConfig,
} from "./webhook";
import {
  watchAddProject,
  watchDeleteProject,
  watchGetProjectList,
  watchSetProjectLimits,
} from "./projects";
import { watchGetAccountLimits, watchGetAccountStatus } from "./account";

function* rootSaga() {
  yield all([
    // User
    watchGetProfile(),
    watchVerifyProfile(),
    watchRegisterProfile(),
    watchInvalidateToken(),
    watchResendVerificationEmail(),
    watchAddEmail(),
    watchReauthAccount(),
    watchUpdateProfile(),
    watchTrackSignup(),

    // Insights
    watchGetUsageInsights(),
    watchGetFailedTransactionInsights(),
    watchGetGraphInsights(),

    // Settings
    watchGetVerificationSettings(),
    watchSetSenderDomain(),
    watchVerifySenderDomain(),
    watchSendTestEmailSPF(),
    watchResetSenderDomain(),
    watchGetSenderDomainVerificationKeys(),
    watchSendSenderVerificationKeysEmail(),
    watchSetSenderIntegrationStatus(),
    watchGetTrackOptionsStatus(),
    watchSetTrackOptionsStatus(),

    // Logs
    watchGetLogs(),
    watchGetLogsByDate(),
    watchGetLogsByEmail(),

    // Access Keys
    watchGetAccessKeys(),
    watchGenerateAccessKey(),
    watchRegenerateAccessSecret(),
    watchRemoveAccessKey(),

    // Ref Service
    watchGetReferralStatsByEmail(),
    watchCreateReferralInvite(),

    watchGetSubscriptionURL(),
    watchGetSubscriptionStatus(),
    watchGetSubscriptionEditURL(),
    watchGetPricing(),

    watchGetSuppressionBounces(),
    watchRemoveSuppressionBounce(),
    watchGetSuppressionSpams(),
    watchGetSuppressionUnsubscribes(),
    watchRedeemPromo(),
    watchGetRedeemedPromo(),

    watchGetWebhookConfig(),
    watchAddWebhookConfig(),
    watchUpdateWebhookConfig(),
    watchDeleteWebhookConfig(),
    watchGetWebhookLogs(),

    watchGetProjectList(),
    watchAddProject(),
    watchDeleteProject(),
    watchSetProjectLimits(),

    watchGetAccountLimits(),
    watchGetInvoices(),
    watchGetSubscriptionMigrationStatus(),
    watchGetSubscriptionMigrationUrl(),
    watchDedicatedIP(),
    watchAllocateDedicatedIP(),
    watchVerifyDedicatedIP(),
    watchGetDedicatedIPPricing(),
    watchSignupRequestLead(),
    watchLoginWithMagicLink(),
    watchCheckMagicLinkAuthStatus(),
    watchResendMagicLink(),
    watchGetAccountStatus(),
  ]);
}

export default rootSaga;
