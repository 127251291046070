import { call, put, take } from "redux-saga/effects";
import { IAction } from "../../schema";
import { ConfigHttp, ProjectConfigHttp } from "../../services/http";
import {
  addProjectAction,
  deleteProjectAction,
  getProjectListAction,
  IAddProject,
  IAddProjectArgs,
  IDeleteProjectArgs,
  IGetProjectList,
  IGetProjectListArgs,
  ISetProjectLimitsArgs,
  projectConst,
  setProjectLimitsAction,
} from "../actions/projects";

function* getProjectListHandler(args: IGetProjectListArgs) {
  try {
    yield put(getProjectListAction.loading());

    const resp: IGetProjectList = yield ConfigHttp({ token: args.token }).get(
      "/project"
    );

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(getProjectListAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(getProjectListAction.error(e.message));
  }
}

export function* watchGetProjectList() {
  while (true) {
    const { payload }: IAction<IGetProjectListArgs> = yield take(
      projectConst.GET_PROJECT_LIST
    );
    yield call(getProjectListHandler, payload);
  }
}

function* addProjectHandler(args: IAddProjectArgs) {
  try {
    yield put(addProjectAction.loading());

    const resp: IAddProject = yield ConfigHttp({
      token: args.token,
    }).post("/project", {}, JSON.stringify({ name: args.name }));

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(addProjectAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(addProjectAction.error(e.message));
  }
}

export function* watchAddProject() {
  while (true) {
    const { payload }: IAction<IAddProjectArgs> = yield take(
      projectConst.ADD_PROJECT
    );
    yield call(addProjectHandler, payload);
  }
}

function* setProjectLimitsHandler(args: ISetProjectLimitsArgs) {
  try {
    yield put(setProjectLimitsAction.loading());

    const resp: IAddProject = yield ProjectConfigHttp({
      token: args.token,
      projectId: args.projectId,
    }).post(
      "/limits",
      {},
      JSON.stringify({
        per_month: args.per_month,
      })
    );

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(setProjectLimitsAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(setProjectLimitsAction.error(e.message));
  }
}

export function* watchSetProjectLimits() {
  while (true) {
    const { payload }: IAction<ISetProjectLimitsArgs> = yield take(
      projectConst.SET_LIMIT
    );
    yield call(setProjectLimitsHandler, payload);
  }
}

function* deleteProjectHandler(args: IDeleteProjectArgs) {
  try {
    yield put(deleteProjectAction.loading());

    const resp: IAddProject = yield ConfigHttp({
      token: args.token,
    }).delete(`/project/${args.projectId}`, {});

    if (resp.error) {
      throw new Error(resp.error);
    }

    yield put(deleteProjectAction.success(resp));
  } catch (e) {
    // @ts-ignore
    yield put(deleteProjectAction.error(e.message));
  }
}

export function* watchDeleteProject() {
  while (true) {
    const { payload }: IAction<IDeleteProjectArgs> = yield take(
      projectConst.DELETE_PROJECT
    );
    yield call(deleteProjectHandler, payload);
  }
}
