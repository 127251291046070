import { createAction } from ".";
import {
  ILRError,
  IAPIError,
  ISideEffects,
  IMojoAuthError,
} from "../../schema";

export const userConst = {
  FETCH_PROFILE: "FETCH_PROFILE",
  VERIFY_PROFILE: "VERIFY_PROFILE",
  REGISTER_PROFILE: "REGISTER_PROFILE",
  INVALIDATE_TOKEN: "INVALIDATE_TOKEN",
  RESEND_VERIFICATION_EMAIL: "RESEND_VERIFICATION_EMAIL",
  ADD_EMAIL: "ADD_EMAIL",
  REAUTH_ACCOUNT: "REAUTH_ACCOUNT",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  TRACK_SIGNUP: "TRACK_SIGNUP",
  REGISTER_REQUEST_LEAD: "REGISTER_REQUEST_LEAD",
  LOGIN_MAGIC_LINK: "LOGIN_MAGIC_LINK",
  RESEND_MAGIC_LINK: "RESEND_MAGIC_LINK",
  CHECK_MAGIC_LINK_AUTH_STATUS: "CHECK_MAGIC_LINK_AUTH_STATUS",
};

export interface IGetProfile extends Profile, IMojoAuthError {}

export interface IGetProfileArgs {
  token: string;
  skip_fetch?: boolean;
}
export const getProfileAction = createAction<IGetProfile, IGetProfileArgs>(
  userConst.FETCH_PROFILE
);

export interface IVerifyIsPosted {
  IsPosted?: boolean;
}
export interface IVerifyProfile extends IVerifyIsPosted, ILRError {}
export interface IVerifyProfileArgs {
  otp?: string;
  email?: string;
}
export const verifyProfileAction = createAction<
  IVerifyProfile,
  IVerifyProfileArgs
>(userConst.VERIFY_PROFILE);

export interface IRegisterProfileData {
  //Profile: IProfile;
  access_token: string;
  expires_in: string;
  refresh_token: string;
}
export interface IRegisterProfile extends ILRError {
  IsPosted?: boolean;
  Data?: IRegisterProfileData;
}
export interface IRegisterProfileArgs {
  email: any[];
  password: string;
  firstName: string;
  company: string;
  customFields: IRegisterProfileCustomFieldsArgs;
}
export interface IRegisterProfileCustomFieldsArgs {
  //company_role: string;
  website_url: string;
}
export const registerProfileAction = createAction<
  IRegisterProfile,
  IRegisterProfileArgs
>(userConst.REGISTER_PROFILE);

export interface IInvalidateToken extends IAPIError {}
export interface IInvalidateTokenArgs {
  token: string;
}
export const invalidateTokenAction = createAction<
  IInvalidateToken,
  IInvalidateTokenArgs
>(userConst.INVALIDATE_TOKEN);

export interface IResendVerificationEmail extends IVerifyIsPosted, ILRError {}
export interface IResendVerificationEmailArgs {
  email?: string;
}
export const resendVerificationEmailAction = createAction<
  IResendVerificationEmail,
  IResendVerificationEmailArgs
>(userConst.RESEND_VERIFICATION_EMAIL);

//
export interface IAddEmail extends IVerifyIsPosted, ILRError {}
export interface IAddEmailArgs {
  email?: string;
  type?: string;
  token?: string;
}
export const addEmailAction = createAction<IAddEmail, IAddEmailArgs>(
  userConst.ADD_EMAIL
);

// Reauth
export interface IReauth extends ILRError {
  SecondFactorValidationToken?: string;
  ExpireIn?: string;
}
export interface IReauthArgs {
  password?: string;
  token: string;
}
export const reauthAccountAction = createAction<IReauth, IReauthArgs>(
  userConst.REAUTH_ACCOUNT
);

// Update Profile
export interface IUpdateProfile extends ILRError {
  IsPosted?: boolean;
}
export interface IUpdateProfileArgs {
  token: string;
  Payload: IUpdateProfilePayload;
}
export interface IUpdateProfilePayload {
  CustomFields?: CustomFields;
}
export interface CustomFields {
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_term?: string;
  adgroup?: string;
  network?: string;
  keyword?: string;
  creative?: string;
  gclid?: string;
  gcl?: string;
}
export const updateProfileAction = createAction<
  IUpdateProfile,
  IUpdateProfileArgs
>(userConst.UPDATE_PROFILE);

// Track Signup
export interface ITrackSignup extends ISideEffects {}
export interface ITrackSignupArgs {
  name: string;
  email: string;
  utm_source: string;
}
export const trackSignupAction = createAction<ITrackSignup, ITrackSignupArgs>(
  userConst.TRACK_SIGNUP
);

// Registration Request
export interface IRegisterRequest extends ILRError {
  IsPosted?: boolean;
}
export interface IRegisterRequestArgs {
  email: string;
  company_name: string;
  name: string;
  role: string;
  website_url: string;
  phone_no: string;
}
export const registerRequestAction = createAction<
  IRegisterRequest,
  IRegisterRequestArgs
>(userConst.REGISTER_REQUEST_LEAD);

// Magic Link Login Action
export interface ILoginWithMagicLink {
  state?: string;
  description?: string;
  code?: number;
  message?: string;
}
export interface ILoginWithMagicLinkRequest extends IMojoAuthError {
  state_id?: string;
}
export interface ILoginWithMagicLinkRequestArgs {
  email: string;
  redirectUrl: string;
}
export const loginWithMagicLinkRequestAction = createAction<
  ILoginWithMagicLinkRequest,
  ILoginWithMagicLinkRequestArgs
>(userConst.LOGIN_MAGIC_LINK);

// Resend Magic Link Action
export interface IResendMagicLinkRequest extends IMojoAuthError {
  state_id?: string;
}
export interface IResendMagicLinkRequestArgs {
  state_id?: string;
  redirectUrl: string;
}
export const resendMagicLinkRequestAction = createAction<
  IResendMagicLinkRequest,
  IResendMagicLinkRequestArgs
>(userConst.RESEND_MAGIC_LINK);

// Check Auth Status with StateId
export interface ICheckMagicLinkAuthStatusRequestArgs {
  state_id?: string;
}

export interface ICheckMagicLinkAuthStatusRequest extends IMojoAuthError {
  authenticated?: boolean;
  access_token?: string;
  token_type?: string;
  expires_at?: string;
  profile?: Profile;
}

export interface Profile {
  id: string;
  account_id: string;
  connection_id: string;
  connection_type: string;
  email: string;
  first_name: string;
  last_name: string;
  raw_attributes: RawAttributes;
  is_active: boolean;
}

export interface RawAttributes {
  external_user_id: string;
}

export const checkMagicLinkAuthStatusRequestAction = createAction<
  ICheckMagicLinkAuthStatusRequest,
  ICheckMagicLinkAuthStatusRequestArgs
>(userConst.CHECK_MAGIC_LINK_AUTH_STATUS);
